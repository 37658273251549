import { createAsync, useSubmission } from '@solidjs/router';
import { Checkbox, Dialog, DialogAction, DialogActions, DialogContent, Form, Label } from '@troon/ui';
import { createEffect, createSignal, onMount, Show, Suspense } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { gql, mutationAction, useMutation } from '../graphql';
import { cachedQuery } from '../graphql/cached-get';
import { createContentfulRequest } from '../routes/content/[model]/_client';
import { RichText } from '../components/rich-text';
import { useUser } from '../providers/user';
import type { JSXElement, ParentProps } from 'solid-js';

export type WaiverHandler = { confirm: (onComplete: () => void) => void };

type Props = ParentProps<{
	ref: (handler: WaiverHandler) => void;
	trigger?: JSXElement;
}>;

export function Waiver(props: Props) {
	const [showWaiver, setShowWaiver] = createSignal(false);
	const [onComplete, setOncomplete] = createSignal(() => {});
	const [checked, setChecked] = createSignal(false);
	const [completed, setCompleted] = createSignal(false);
	const user = useUser();
	const trackEvent = useTrackEvent();

	const waiverStatus = createAsync(async () => (user() ? getWaiverStatus({}) : null), { deferStream: false });
	const content = createAsync(async () => getEntry('course-waiver'), { deferStream: true });

	const signWaiverAction = useMutation(signWaiver);
	const submission = useSubmission(signWaiverAction);

	createEffect(() => {
		if (submission.result?.data && !completed()) {
			setCompleted(true);
			setShowWaiver(false);
			onComplete()();
		}
	});

	createEffect(() => {
		trackEvent('dialogOpened', { key: 'waiver' });
	});

	onMount(() => {
		props.ref({
			confirm: (onComplete: () => void) => {
				if (waiverStatus()?.userWaiver) {
					setCompleted(true);
					onComplete();
					return;
				}

				setOncomplete(() => onComplete);
				setShowWaiver(true);

				return;
			},
		});
	});

	return (
		<Suspense>
			<Show when={!waiverStatus.latest?.userWaiver?.lastSignedDate} fallback={props.children}>
				<Dialog key="waiver" open={showWaiver()} onOpenChange={setShowWaiver}>
					{props.trigger}
					<DialogContent header="Waiver And Release Of Liability" headerLevel="h2">
						<Form action={signWaiverAction} document={signWaiverMutation}>
							<p>
								Please read the following waiver and release of liability (“waiver”) carefully. By checking the box
								below or clicking “I agree,” you acknowledge that you have read, understood, and agree to be bound by
								this agreement.
							</p>

							<div class="max-h-64 overflow-y-auto rounded border border-neutral p-4 text-sm">
								<Suspense>
									<Show when={content()?.fields.content}>{(content) => <RichText document={content()} />}</Show>
								</Suspense>
							</div>

							<Checkbox
								required
								name="agree"
								onChange={(e) => {
									setChecked(e.target.checked);
								}}
							>
								<Label>I have read and agree to the terms of this Waiver and Release of Liability.</Label>
							</Checkbox>
							<DialogActions>
								<DialogAction disabled={!checked()} type="submit">
									I agree
								</DialogAction>
							</DialogActions>
						</Form>
					</DialogContent>
				</Dialog>
			</Show>
		</Suspense>
	);
}

const waiverQuery = gql(`query userWaiver {
	userWaiver {
		lastSignedDate
	}
}`);

const getWaiverStatus = cachedQuery(waiverQuery, { onError: () => null });

const signWaiverMutation = gql(`mutation signWaiver {
	signWaiver {
		lastSignedDate
	}
}`);

const signWaiver = mutationAction(signWaiverMutation, { track: { event: 'signedWaiver' } });

const getEntry = createContentfulRequest('plain-document');
